import { useState, useEffect } from "react";

import { faqData } from "../../utils/utils";

import appLogo from "../../images/bot-logo.svg";
import mobileMain from "../../images/banner-GIF.gif";
import clock from "../../images/clock.svg";
import settingsIcon from "../../images/settings.svg";
import checkList from "../../images/check-list.svg";
import whatsAppMobile from "../../images/mobile-whatsapp.png";
import websiteLap from "../../images/lap-top-web.png";
import instoreSS from "../../images/ss-instore.png";
import forever21 from "../../images/forever-21.png";
import azirte from "../../images/azirte.png";
import raymond from "../../images/raymond-icon.png";
import farfetch from "../../images/fareetch.png";
import adityaBirla from "../../images/aditya-birla.png";
import clothsPopping from "../../images/clothes-popping.png";
import catalogixIcon from "../../images/catalogix.svg";
import purpleStarts from "../../images/purple-stars.svg";
import closeIcon from "../../images/close-icon.svg";
import earRing1 from "../../images/acc-ear-rings-1.png";
import earRing2 from "../../images/acc-ear-rings.png";
import heals from "../../images/acc-heals.png";
import purse from "../../images/acc-purse.png";
import dress1 from "../../images/dress-1.png";
import dress2 from "../../images/dress-2.png";
import american from "../../images/american.svg";

import customerSupport from "../../videos/customer-support.webm";
import dynamicSales from "../../videos/dynamic-sales-assistance.webm";
import expertStylist from "../../videos/expert-stylist.webm";

import TextInput from "../../components/TextInput";

import "./style.scss";

const Home = () => {
  const emailRegex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
  const [activeIndex, setActiveIndex] = useState();
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [coyName, setCoyName] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [formError, setFormError] = useState(null);
  const [showSubmissionMessage, setShowSubmissionMessage] = useState(false);

  useEffect(() => {
    if (showSubmissionMessage) {
      setTimeout(() => {
        setShowSubmissionMessage(false);
      }, 3000);
    }
  }, [showSubmissionMessage]);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const validateForm = () => {
    let temp = {};

    if (!userName || userName.length < 3) {
      temp.userName = "Invalid User name";
      setFormError(temp);
      return "Enter a valid name";
    } else if (!emailRegex.test(email)) {
      if (!emailRegex.test(email)) {
        temp.userEmail = "Invalid email";
      }
      setFormError(temp);
    } else if (coyName.length < 2) {
      setFormError(temp);
      return "Company name can't less less than 2 letters.";
    } else {
      return "success";
    }
  };

  const handleOnSubmitForm = async (e) => {
    e.preventDefault();
    const validMessage = validateForm();

    if (validMessage === "success") {
      const data = {
        name: userName,
        email: email,
        company: coyName,
      };

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      try {
        const response = await fetch(
          "https://api.photogenix.ai/api/v1/contact-stylebot",
          options
        );
        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData);
          setCoyName("");
          setEmail("");
          setUsername("");
          setShowSubmissionMessage(true);
        }
      } catch (err) {
        console.log("Error: ", err);
      }
    } else {
      console.log(validMessage);
    }
  };

  return (
    <main className="main-container">
      <>
        <article className="home-section" id="home">
          <nav className="nav-container">
            <div className="logo-container">
              <img src={appLogo} alt="app-logo" />
            </div>
            <div className="contact-btn">
              <a href="#contact"> Schedule Demo</a>
            </div>
          </nav>
          <section className="home-sub-container">
            <div className="header-main-container">
              <div className="header-sub-container">
                <h1 className="optimize-header">Optimize sales</h1>
                <p className="optimize-sub-header">
                  for your fashion brand using a{" "}
                  <span>custom virtual stylist</span>
                </p>
              </div>
              <button type="button" className="demo-btn">
                <a href="#contact"> Schedule Demo</a>
              </button>
            </div>
            <>
              <img src={mobileMain} alt="mobileMain" className="mobile-main" />
            </>
          </section>
        </article>
        <article className="experience-sec">
          <div className="chat-interface">
            <div className="interface-header-container">
              <h3>Why imagine when you can experience?</h3>
              <p>
                Test our Style Assist demo today for a taste of personalized
                style recommendations
              </p>
            </div>
            <div className="interface-input-container">
              <button
                className="try-now"
                type="button"
                onClick={() => setShowIframe(true)}
              >
                Try Now
              </button>
              <div className="star-container">
                <img src={purpleStarts} alt="purpleStarts" />
              </div>

              <div className="acc-left">
                <div className="acc-desc">
                  {" "}
                  What should I pair this dress with?
                </div>
                <div className="acc-main">
                  <div className="img-container">
                    <img src={purse} alt="purse" />
                  </div>
                  <div className="img-container">
                    <img src={heals} alt="heals" />
                  </div>
                </div>
              </div>
              <div className="acc-center-left">
                <div className="acc-desc">
                  What top should I wear for an evening with the girls?
                </div>
                <div className="acc-main">
                  <div className="img-container">
                    <img src={dress2} alt="dress2" />
                  </div>
                </div>
              </div>
              <div className="acc-center-right">
                <div className="acc-desc">
                  I have broad shoulders. What kind of top should I wear?
                </div>
                <div className="acc-main">
                  <div className="img-container">
                    <img src={dress1} alt="dress1" />
                  </div>
                </div>
              </div>
              <div className="acc-right">
                <div className="acc-desc">
                  What earrings should I pair this top with?
                </div>
                <div className="acc-main">
                  <div className="img-container">
                    <img src={earRing1} alt="earRing1" />
                  </div>
                  <div className="img-container">
                    <img src={earRing2} alt="earRing2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="interface-sub-mobile-container">
              <button
                className="try-now"
                type="button"
                onClick={() => setShowIframe(true)}
              >
                Try Now
              </button>
              <div className="star-container">
                <img src={purpleStarts} alt="purpleStarts" />
              </div>
              <div className="interface-details-container-main">
                <div className="interface-details-container">
                  <div className="desc-img-container">
                    <div className="desc-container">
                      <div className="acc-desc">
                        I have broad shoulders. What kind of top should I wear?
                      </div>
                      <div className="acc-desc">
                        What earrings should I pair this top with?
                      </div>
                    </div>
                    <div className="images-container">
                      <div className="img-container">
                        <img
                          src={earRing1}
                          alt="earRing1"
                          className="small-img"
                        />
                      </div>
                      <div className="img-container">
                        <img
                          src={earRing2}
                          alt="earRing2"
                          className="small-img"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="img-container">
                      <img src={dress1} alt="dress1" />
                    </div>
                  </div>
                </div>
                <div className="interface-details-container">
                  <div>
                    <div className="img-container">
                      <img src={dress2} alt="dress2" />
                    </div>
                  </div>
                  <div className="desc-img-container">
                    <div className="desc-container">
                      <div className="acc-desc">
                        I have broad shoulders. What kind of top should I wear?
                      </div>
                      <div className="acc-desc">
                        What earrings should I pair this top with?
                      </div>
                    </div>
                    <div className="images-container">
                      <div className="img-container">
                        <img src={purse} alt="purse" className="small-img" />
                      </div>
                      <div className="img-container">
                        <img src={heals} alt="heals" className="small-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="benefits">
          <div>
            <h2>Benefits</h2>
          </div>
          <section className="expert">
            <div className="benefits-desc">
              <h6>Expert Stylist</h6>
              <p>
                Elevate your brand with a knowledgeable stylist, adept in
                current trends quick to align with brand guidelines.
              </p>
            </div>
            <div>
              <video autoPlay loop muted>
                <source src={expertStylist} type="video/mp4" />
              </video>
            </div>
          </section>
          <section className="customer-support">
            <div>
              <video autoPlay loop muted>
                <source src={customerSupport} type="video/mp4" />
              </video>
            </div>
            <div className="benefits-desc">
              <h6>Efficient Customer Support:</h6>
              <p>
                Enjoy unparalleled customer service - swift, precise, and
                capable of managing multiple users seamlessly.
              </p>
            </div>
          </section>
          <section className="sales-asst">
            <div className="benefits-desc">
              <h6>Dynamic Sales Assistant :</h6>
              <p>
                Optimize sales with out real-time catalog-informed virtual
                assistant, providing intelligent and tailored product
                suggestions.
              </p>
            </div>
            <div>
              <video autoPlay loop muted>
                <source src={dynamicSales} type="video/mp4" />
              </video>
            </div>
          </section>
        </article>
        <article className="features">
          <h2>Features</h2>
          <section className="features-section">
            <div className="feature-container">
              <div>
                <img src={checkList} alt="checkList" />
              </div>
              <h6>Custom Guidelines</h6>
              <p>Tailored towards your brand</p>
            </div>
            <div className="feature-container">
              <div>
                <img src={settingsIcon} alt="settingsIcon" />
              </div>
              <h6>Effortless Integration</h6>
              <p> Effortless setup for quick integration</p>
            </div>
            <div className="feature-container">
              <div>
                <img src={clock} alt="clock" />
              </div>
              <h6>Real-Time Product Sync</h6>
              <p>Stays updated with your catalog</p>
            </div>
          </section>
        </article>
        <article className="know-if-needed" id="about">
          <h2>
            How to know if <span>StyleAssist</span> is right for you
          </h2>
          <section>
            <div className="needs-sub-container">
              <div className="left">
                You want to transform your in-store experience with an AI
                Stylist to enhance your brand's appeal.
              </div>
              <div className="right">
                Your fashion brand’s website has a conversion below 5%
              </div>
            </div>
            <div className="needs-sub-container">
              <div className="left">
                You want to evolve to a tech-first showroom, reducing in-store
                staff reliance.
              </div>
              <div className="right">
                Your customers need styling advice while making a purchase.
              </div>
            </div>
            <div className="needs-sub-container">
              <div className="left">
                You want to increase your brand awareness in the AI landscape.
              </div>
              <div className="right">
                You want to have a new sales channel as your brand’s own AI
                Stylist on whatsapp.
              </div>
            </div>
          </section>
        </article>
        <article className="integrate-section" id="integration">
          <h2>Integrate with</h2>
          <section></section>
          <section>
            <div className="integrate-in">
              <img src={whatsAppMobile} alt="whats-app" />
              <h6>Whatsapp</h6>
              <p>
                Seamlessly integrate our virtual styling assistant as a WhatsApp
                bot, unlocking a new channel for users to engage with your
                products effortlessly.
              </p>
            </div>
            <div className="integrate-in">
              <img src={websiteLap} alt="lap-top" />
              <h6>Website</h6>
              <p>
                Elevate user experience on your website by integrating our
                assistant, reducing product discovery time and minimizing user
                churn.
              </p>
            </div>
            <div className="integrate-in">
              <img src={instoreSS} alt="instoreSS" />
              <h6>In-Store</h6>
              <p>
                Enhance the in-store experience for your buyers with our virtual
                assistant, enabling them to ask pertinent questions and actively
                contributing to increased sales.
              </p>
            </div>
          </section>
        </article>
        <article className="working-brands">
          <h2>Working with brands like</h2>
          <section>
            <div>
              <img src={forever21} alt="forever21" />
            </div>
            <div className="vr-divider"></div>
            <div>
              <img src={azirte} alt="azirte" />
            </div>
            <div className="vr-divider"></div>
            <div>
              <img src={raymond} alt="raymond" />
            </div>
            <div className="vr-divider"></div>
            <div>
              <img src={farfetch} alt="farfetch" />
            </div>
            <div className="vr-divider"></div>
            <div>
              <img src={adityaBirla} alt="adityaBirla" />
            </div>
            <div className="vr-divider"></div>
            <div>
              <img src={american} alt="american" />
            </div>
          </section>
        </article>
        <article className="faq-art">
          <h2>
            Our most <span>frequently</span> asked questions
          </h2>
          <div className="faq">
            {faqData.map((faq, idx) => {
              return (
                <div key={idx}>
                  <button
                    className={`accordion ${
                      activeIndex === idx ? "active" : ""
                    }`}
                    onClick={() => handleToggle(idx)}
                  >
                    {faq.question}
                  </button>
                  <p className={`panel ${activeIndex === idx ? "active" : ""}`}>
                    {faq.answer}
                  </p>
                </div>
              );
            })}
          </div>
        </article>
        <article className="contact-art" id="contact">
          <div className="contact-form-container">
            <h3>Let’s Create some fashionable moments together!</h3>
            <p>Sign up to schedule a demo for StyleAssist</p>
            <form className="form" onSubmit={handleOnSubmitForm}>
              <div>
                <TextInput
                  inputValue={userName}
                  label="Name"
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter Your Name..."
                  lableClass="form-label"
                  containerClass="container-class"
                />
                {formError && formError.userName && (
                  <span>Invalid userName</span>
                )}
              </div>
              <div>
                <TextInput
                  inputValue={email}
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Your Email..."
                  lableClass="form-label"
                  containerClass="container-class"
                />
                {formError && formError.userEmail && <span>Invalid Email</span>}
              </div>
              <div>
                <TextInput
                  inputValue={coyName}
                  label="Company Name"
                  onChange={(e) => setCoyName(e.target.value)}
                  placeholder="Enter Your Company Name..."
                  lableClass="form-label"
                  containerClass="container-class"
                />
                {formError && formError.coyName && (
                  <span>Invalid Company name</span>
                )}
              </div>
              <input
                type="submit"
                value="Schedule demo"
                className="submit-btn"
              />
              {showSubmissionMessage && (
                <div className="submit-message">
                  Thanks.! for reaching out to us, Will get back to you soon...
                </div>
              )}
            </form>
          </div>
          <div className="cloths-poping">
            <img src={clothsPopping} alt="clothsPopping" />
          </div>
        </article>
        <footer>
          <section className="foot-title">
            <h2>StyleAssist</h2>
            <div>
              <p>Product of</p>
              <a href="https://www.catalogix.ai/">
                <img src={catalogixIcon} alt="catalogixIcon" />
              </a>
            </div>
          </section>
          <section className="footer-links">
            <a href="#home">Home</a>
            <a href="#about">About</a>
            <a href="#integration">Integration</a>
            <a href="#contact">Contact US</a>
          </section>
        </footer>
      </>
      {showIframe && (
        <div className="iframe-modal">
          <div className="close-btn" onClick={() => setShowIframe(false)}>
            <img src={closeIcon} alt="closeIcon" />
          </div>
          <div className="iframe-sub">
            <iframe
              title="StyleBot"
              src="https://stylebot.streamoid.com/"
              width="470"
              height="750"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </main>
  );
};

export default Home;
