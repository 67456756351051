import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";


import "./App.scss";


// To add authentication wrap the element in ProtectedRoute eg: <ProtectedRoute><Home/><ProtectedRoute/>

// const ProtectedRoute = ({ children }) => {
//   const user = localStorage.getItem("stylebot_token");
//   if (!user) {
//     return <Navigate to="/login" replace />;
//   }
//   return children;
// };

const App = () => {
  return (
    <>
      {/* <NavBar /> */}
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
};

export default App;
