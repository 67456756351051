import "./style.scss";

const TextInput = (props) => {
  return (
    <div
      className={props.containerClass ? props.containerClass : "inp-contaier"}
    >
      <div className={props.lableClass ? props.lableClass : "label"}>
        {props.label ? props.label : ""}
      </div>
      <div className="inp-ele-container">
        <input
          className="inp-ele"
          onChange={props.onChange}
          value={props.inputValue}
          type={props.type ? props.type : "text"}
          placeholder={props.placeholder ? props.placeholder : "Type Something"}
        />
      </div>
    </div>
  );
};

export default TextInput;
