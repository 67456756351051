
export const faqData = [
  {
    question:
      "What is a virtual stylist, and how can it benefit my fashion brand?",
    answer:
      "You can integrate our virtual stylist with WhatsApp, allowing users to engage with your products effortlessly through a WhatsApp bot. Additionally, it can be seamlessly integrated into your website to enhance the user experience and reduce product discovery time. In-store integration is also available, contributing to an improved in-store experience for your buyers.",
  },
  {
    question: "How does the virtual stylist work in real-time?",
    answer:
      "You can integrate our virtual stylist with WhatsApp, allowing users to engage with your products effortlessly through a WhatsApp bot. Additionally, it can be seamlessly integrated into your website to enhance the user experience and reduce product discovery time. In-store integration is also available, contributing to an improved in-store experience for your buyers.",
  },
  {
    question:
      "Can I customize the stylist's recommendations to align with my brand effortlessly?",
    answer:
      "You can integrate our virtual stylist with WhatsApp, allowing users to engage with your products effortlessly through a WhatsApp bot. Additionally, it can be seamlessly integrated into your website to enhance the user experience and reduce product discovery time. In-store integration is also available, contributing to an improved in-store experience for your buyers.",
  },
  {
    question: "What channels can I integrate the virtual stylist with?",
    answer:
      "You can integrate our virtual stylist with WhatsApp, allowing users to engage with your products effortlessly through a WhatsApp bot. Additionally, it can be seamlessly integrated into your website to enhance the user experience and reduce product discovery time. In-store integration is also available, contributing to an improved in-store experience for your buyers.",
  },
  {
    question: "How does the virtual stylist contribute to sales optimization?",
    answer:
      "You can integrate our virtual stylist with WhatsApp, allowing users to engage with your products effortlessly through a WhatsApp bot. Additionally, it can be seamlessly integrated into your website to enhance the user experience and reduce product discovery time. In-store integration is also available, contributing to an improved in-store experience for your buyers.",
  },
];
